/* UserList.css */
.table-container {
    font-family: Arial, sans-serif;
  }
  
  .table-container table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-container th,
  .table-container td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .table-container th {
    background-color: #e3d7f7; /* Color lila para los encabezados de la tabla */
  }
  
  .table-container tbody tr:nth-child(even) {
    background-color: #f7e3f1; /* Color rosa para filas pares de la tabla */
  }
  
  .table-container tbody tr:hover {
    background-color: #fcd5e5; /* Color rosa más claro cuando se pasa el cursor sobre una fila */
  }

.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo oscurecido con 50% de opacidad */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: plum;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.input-container {
    display: flex;
    flex-direction: column;
  }
  
  .input-container > div {
    margin-bottom: 10px;
  }
  
  